import { PaletteOptions, ThemeOptions } from '@mui/material/styles';
import '@mui/material/Button';
import '@mui/material/Typography';

import brandonGrotesqueBlack from './fonts/BrandonGrotesque-Black.woff';
import brandonGrotesqueBold from './fonts/BrandonGrotesque-Bold.woff';
import brandonGrotesqueRegular from './fonts/BrandonGrotesque-Regular.woff';
import specialCalibriRegular from './fonts/SpecialCalibri-Regular.woff';
import specialCalibriRegularBold from './fonts/SpecialCalibri-Regular-Bold.woff';
import specialCalibriItalic from './fonts/SpecialCalibri-Italic.woff';
import specialCalibriItalicBold from './fonts/SpecialCalibri-Italic-Bold.woff';
import React from 'react';

// module augmentation to add typing to the abbott palette
declare module '@mui/material/styles' {
  interface PaletteOptions {
    abbott: {
      red: string;
      gold: string;
      mint: string;
      white: string;
      black: string;
      purple: string;
      yellow: string;
      primary: string;
      magenta: string;
      darkGray: string;
      charcoal: string;
      darkBlue: string;
      lightGray: string;
      secondary: string;
      lightBlue: string;
      mediumGray: string;
      mediumBlue: string;
      lightGreen: string;
      primaryBlue: string;
      mediumGreen: string;
      transparentRed: string;
      text: string;
    };
  }
  interface TypographyVariants {
    icon: React.CSSProperties;
    iconSmall: React.CSSProperties;
    iconLarge: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    icon?: React.CSSProperties;
    iconSmall?: React.CSSProperties;
    iconLarge?: React.CSSProperties;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    fit: true;
    rounded: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    regularHeadingPrimary: true;
    messageHeading: true;
    stepperHeading: true;
  }
}

const abbottColors = {
  red: '#e4002b',
  gold: '#eeb33b',
  mint: '#64ccc9',
  white: '#ffffff',
  black: '#000000',
  purple: '#470a68',
  yellow: '#ffd100',
  primary: '#009cde',
  magenta: '#aa0061',
  darkGray: '#63666a',
  charcoal: '#222731',
  darkBlue: '#002a3a',
  lightGray: '#d9d9d6',
  secondary: '#63666a',
  lightBlue: '#5bc2e7',
  mediumGray: '#888b8d',
  mediumBlue: '#004f71',
  lightGreen: '#7ccc6c',
  primaryBlue: '#009cde',
  mediumGreen: '#00b140',
  transparentRed: '#e4002b1a',
  highlightBlue: '#009cde29',
  text: '#63666a',
};

const buttonVariants = ['fit', 'rounded'];

export const fontSizes = {
  xs: 12,
  s: 14,
  m: 16,
  l: 24,
  xl: 32,
  xxl: 40,
};

export default {
  typography: {
    fontFamily: ['Calibri', 'Regular'].join(','),
    lineHeight: '20px',
    color: abbottColors.darkGray,
    letterSpacing: '0',
    textTransform: 'uppercase',
    fontWeight: 700,
    h1: {
      fontFamily: ['BrandonGrotesque'].join(','),
      fontSize: '1.125rem',
      lineHeight: '1.375rem',
      color: abbottColors.darkGray,
      letterSpacing: 0,
      textTransform: 'uppercase',
      fontWeight: 700,
    },
    h2: {
      fontFamily: ['BrandonGrotesque'].join(','),
      color: 'abbott.darkGray',
      fontSize: '30px',
      lineHeight: '36px',
      letterSpacing: 0,
      textTransform: 'uppercase',
      fontWeight: 700,
    },
    h3: {
      fontFamily: ['BrandonGrotesque'].join(','),
      fontSize: fontSizes.l,
      lineHeight: '24px',
      color: '#009cde',
      letterSpacing: 0,
      textTransform: 'none',
      fontWeight: 'bold',
    },

    h4: {
      fontFamily: ['BrandonGrotesque'].join(','),
      fontSize: '22px',
      lineHeight: '26px',
      color: '#009cde',
      letterSpacing: 0,
      textTransform: 'uppercase',
      fontWeight: 'normal',
    },
    h5: {
      fontFamily: ['BrandonGrotesque'].join(','),
      fontSize: '1.125rem',
      lineHeight: '1.375rem',
      color: '#009cde',
      letterSpacing: 0,
      textTransform: 'uppercase',
      fontWeight: 'normal',
    },
    h6: {
      fontFamily: ['BrandonGrotesque'].join(','),
      fontSize: '1rem',
      lineHeight: '1.25rem',
      color: abbottColors.darkGray,
      letterSpacing: '0',
      textTransform: 'uppercase',
      fontWeight: 'normal',
    },
    button: {
      fontFamily: ['BrandonGrotesque'].join(','),
      fontWeight: 'bold',
      fontSize: '1rem',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: '1.5em',
      color: abbottColors.darkGray,
      letterSpacing: '0',
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: '1em',
      color: abbottColors.darkGray,
      letterSpacing: '0',
    },
    caption: {
      fontSize: '.75rem',
    },
    icon: {
      fontSize: '1rem',
    },
    iconSmall: {
      fontSize: '.75rem',
    },
    iconLarge: {
      fontSize: '2.5rem',
    },
    regularHeadingPrimary: {
      fontFamily: ['BrandonGrotesque'].join(','),
      color: abbottColors.primaryBlue,
      fontSize: '1.125rem',
      fontWeight: 'bold',
    },
    messageHeading: {
      fontFamily: ['BrandonGrotesque'].join(','),
      fontSize: '1.125rem',
      fontWeight: 'bold',
      color: abbottColors.text,
    },
    stepperHeading: {
      fontFamily: ['BrandonGrotesque'].join(','),
      fontSize: '0.875rem',
      fontWeight: 'bold',
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: abbottColors.primary,
      dark: abbottColors.mediumBlue,
      contrastText: abbottColors.white,
    },
    secondary: {
      main: abbottColors.lightGray,
      dark: abbottColors.mediumGray,
      contrastText: abbottColors.charcoal,
    },
    error: {
      main: abbottColors.red,
    },
    success: {
      main: abbottColors.mediumGreen,
    },
    abbott: abbottColors,
  } as PaletteOptions,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: "BrandonGrotesque";
          font-weight: 900;
          font-display: swap;
          src: url(${brandonGrotesqueBlack}) format("woff");

        }
        
        @font-face {
          font-family: "BrandonGrotesque";
          font-weight: 700;
          font-display: swap;
          src: url(${brandonGrotesqueBold}) format("woff");
        }
        
        @font-face {
          font-family: "BrandonGrotesque";
          font-weight: 400;
          font-display: swap;
          src: url(${brandonGrotesqueRegular}) format("woff");
        }

        @font-face {
          font-family: 'Calibri';
          font-style: italic;
          font-weight: 400;
          src: url(${specialCalibriItalic}) format("woff"); format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
      
        @font-face {
            font-family: 'Calibri';
            font-style: italic;
            font-weight: 700;
            src: url(${specialCalibriItalicBold}) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
      
        @font-face {
            font-family: 'Calibri';
            font-style: normal;
            font-weight: 400;
            src: url(${specialCalibriRegular}) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
      
        @font-face {
            font-family: 'Calibri';
            font-style: normal;
            font-weight: 700;
            src: url(${specialCalibriRegularBold}) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(buttonVariants.includes(ownerState.variant ?? '') && {
            padding: theme.spacing(1.25, 2.5, 1, 2.5),
            ...(ownerState.color === 'primary' && {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              '&:hover': {
                backgroundColor: theme.palette.primary.main,
              },
            }),
            ...(ownerState.color === 'secondary' && {
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.secondary.contrastText,
              '&:hover': {
                backgroundColor: theme.palette.secondary.main,
              },
            }),
          }),
        }),
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            fontSize: '.875rem',
          },
        },
        {
          props: { variant: 'fit' },
          style: {
            boxShadow: 'none',
            height: 'auto',
            borderRadius: 0,
          },
        },
        {
          props: { variant: 'rounded' },
          style: {
            boxShadow: 'none',
            height: 'auto',
          },
        },
      ],
    },
    MuiLink: {
      styleOverrides: {
        root: ({ theme }) => ({
          textDecorationColor: 'inherit',
          '&[disabled]': {
            color: theme.palette.grey,
            pointerEvents: 'none',
          },
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          minHeight: '1.125rem',
          display: 'flex',
          alignItems: 'center',
          '& p': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          paddingTop: '5px',
          paddingBottom: '5px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          whiteSpace: 'unset',
          wordBreak: 'break-all',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontSize: '1.125rem',
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: {
          fontSize: '1rem',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          width: '100%',
          padding: '0 2.25rem',
        },
      },
    },
  },
} as ThemeOptions;
